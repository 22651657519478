import Vue from "vue";
import VueRouter from "vue-router";
// import { default as enterpriseRoutes } from './enterprise.js';
// import { getDomainFromUrl } from '../mixin'

Vue.use(VueRouter);

const guest = (to, from, next) => {
  localStorage.removeItem("authToken");
  if (!localStorage.getItem("authToken")) {
    localStorage.removeItem("authToken");
    return next(next);
  } else {
    return next("/");
  }
};
const auth = (to, from, next) => {
  var redirectToProfile = false;
  var redirectToCompany = false;
  if(localStorage.getItem("company_id")){
     redirectToCompany = true;
  }
  if(localStorage.getItem("user_last_name")){
     redirectToProfile = true;
  }
  if (localStorage.getItem("authToken") && localStorage.getItem("permissions") != "1") {
    if(localStorage.getItem("permissions") != 4){
    if(redirectToCompany && redirectToProfile != false){
      return next(); 
    }
    else{
      if(redirectToProfile == false){
        return next("/profile");
      }else if(redirectToCompany == false){
        return next("/company-profile");
      }else{
        return next(); 
      }
    }
  }else {
    return next(next);
  }
  } 
  else {
    localStorage.removeItem("authToken");
    return next("/login");
  }
};
// const authVerfier = (to, from, next) => {
//   if (localStorage.getItem("authToken") && localStorage.getItem("permissions") != "1") {
//     return next(next);
//   } 
//   else {
//     localStorage.removeItem("authToken");
//     return next("/login");
//   }
// };
const simpleAuth = (to, from, next) => {
  if (localStorage.getItem("authToken") && localStorage.getItem("permissions") !== '1') {
      return next(next);
  }
  else {
    localStorage.removeItem("authToken");
    return next("/login");
  }
};
const Enterpriseauth = (to, from, next) => {
  if (localStorage.getItem("authToken")) {
      return next(next);
  }
  else {
    localStorage.removeItem("authToken");
    return next("/login");
  }
};

const adminAuth = (to, from, next) => {
  if (localStorage.getItem("authToken") && localStorage.getItem("permissions") == 1) {
      return next(next);
  }
  else {
    localStorage.removeItem("authToken");
    return next("/login");
  }
};
// const passwordRoute = (to, from, next) => {
//   if (localStorage.getItem("verifyEmail")) {
//     return next();
//   } else {
//     return next("/login");
//   }
// };
// const emailVerify = (to, from, next) => {
//   if (localStorage.getItem("emailToVerify")) {
//     return next();
//   } else {
//     return next("/login");
//   }
// };

const baseRoutes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/registry",
    name: "Homes",
    beforeEnter: simpleAuth,
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/registries",
    name: "Homes",
    beforeEnter: adminAuth,
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Auth/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Register" */ "../views/Auth/Register.vue"),
  },
  {
    path: "/EnterpriseAdminRegister",
    name: "EnterpriseRegister",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Register" */ "../views/EnterpriseViews/enterpriseadminRegister.vue"),
  },
  {
    path: "/enterprisePackage",
    name: "EnterprisePackage",
    beforeEnter: Enterpriseauth,
    component: () =>
      import(/* webpackChunkName: "Register" */ "../views/EnterpriseViews/EnterprisePackage.vue"),
  },
  {
    path: "/verification-email",
    name: "VerificationEmail",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Verification_email"*/ "../views/Auth/VerificationEmail.vue"),
  },
  {
    path: "/verified-email",
    name: "VerifiedEmail",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "verified-email" */ "../views/Auth/VerifiedEmail.vue"),
  },
  {
    path: "/verify/:hash",
    name: "Verify",
    beforeEnter: auth,
    props: true,
    component: () =>
      import(/* webpackChunkName: "Verify" */ "../views/Auth/Verify.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswrod",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/Auth/ForgotPassword/Forgot.vue"
      ),
  },
  {
    path: "/forgot-username",
    name: "ForgotUsername",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "ForgotUsername" */ "../views/Auth/ForgotPassword/ForgotUsername.vue"
      ),
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "VerifyEmail" */ "../views/Auth/ForgotPassword/VerifyEmail.vue"
      ),
  },
  {
    path: "/new-password",
    name: "NewPassword",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "NewPassword" */ "../views/Auth/ForgotPassword/NewPassword.vue"
      ),
  },
  {
    path: "/password-update",
    name: "PasswordUpdate",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "PasswordUpdate" */ "../views/Auth/ForgotPassword/PasswordUpdate.vue"
      ),
  },
  {
    path: "*",
    name: "pageNotFound",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "pageNotFound" */ "../views/PageNotFound.vue"
      )
  },
  {
    path: "/registry-signup",
    name: "RegistrySignupPage",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "RegistrySignupPage" */ "../views/RegistrySignupPage.vue"
      ),
  },
  {
    path: "/globalnda-signup",
    name: "GlobalNDAForm",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "GlobalNDAForm" */ "../views/GlobalNDAForm.vue"
      ),
  },
  {
    path: "/signed-agreements",
    name: "SignedAgreements",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "SignedAgreements" */ "../views/SignedAgreements.vue"
      ),
  },
  {
    path: "/users-signed",
    name: "UsersSigned",
    beforeEnter: adminAuth,
    component: () =>
      import(
        /* webpackChunkName: "SignedAgreements" */ "../views/Admin/User/usersSigned.vue"
      ),
  },
  {
    path: "/companies",
    name: "Companies",
    beforeEnter: adminAuth,
    component: () =>
      import(
        /* webpackChunkName: "SignedAgreements" */ "../views/Admin/Companies/companies.vue"
      ),
  },
  {
    path: "/company-view",
    name: "companyView",
    beforeEnter: adminAuth,
    component: () =>
      import(
        /* webpackChunkName: "SignedAgreements" */ "../views/Admin/Companies/companyView.vue"
      ),
  },
  {
    path: "/company-detail-view",
    name: "companyDetailView",
    beforeEnter: adminAuth,
    component: () =>
      import(
        /* webpackChunkName: "SignedAgreements" */ "../views/Admin/Companies/compUsers&Agreements.vue"
      ),
  },
  {
    path: "/user-view",
    name: "usersView",
    beforeEnter: adminAuth,
    component: () =>
      import(
        /* webpackChunkName: "usersView" */ "../views/Admin/User/usersView.vue"
      ),
  },
  {
    path: "/agreement-detail",
    name: "AgreementDetail",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "AgreementDetail" */ "../views/AgreementDetail.vue"
      ),
  },
  {
    path: "/ndaform-table",
    name: "CompanyAgreements",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "CompanyAgreements" */ "../views/CompanyAgreements.vue"
      ),
  },
  {
    path: "/nda-table",
    name: "CompanySigned",
    beforeEnter: adminAuth,
    component: () =>
      import(
        /* webpackChunkName: "CompanyAgreements" */ "../views/CompanyAgreements.vue"
      ),
  },
  {
    path: "/profile",
    name: "Profile",
    beforeEnter: simpleAuth,
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/Profile.vue"),
  },
  {
    path: "/company-profile",
    name: "CompanyProfilePage",
    beforeEnter: simpleAuth,
    component: () =>
      import(
        /* webpackChunkName: "CompanyProfilePage" */ "../views/CompanyProfilePage.vue"
      ),
  },
  {
    path: "/billing",
    name: "Billing",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Billing" */ "../views/Bill.vue"),
  },
  {
    path: "/packages",
    name: "Packages",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Packages" */ "../views/Packages.vue"),
  },
  {
    path: "/thank",
    name: "Thank",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Thank" */ "../views/Thank.vue"),
  },
  {
    path: "/enterprise-user",
    name: "EnterpriseUser",
    beforeEnter: adminAuth,
    component: () =>
      import(
        /* webpackChunkName: "EnterpriseUser" */ "../views/Admin/User/EnterpriseUserView.vue"
      ),
  },
  {
    path: "/enterprise-userlsit",
    name: "EnterpriseUserList",
    beforeEnter: adminAuth,
    component: () =>
      import(
        /* webpackChunkName: "EnterpriseUserList" */ "../views/Admin/Enterprise/EnterpriseUser.vue"
      ),
  },
  {
    path: "/enterprise-update",
    name: "EnterpriseUserUpdate",
    beforeEnter: adminAuth,
    component: () =>
      import(
        /* webpackChunkName: "EnterpriseUserList" */ "../views/Admin/Enterprise/EnterpriseUserUpdate.vue"
      ),
  },
 {
        path: "/templates",
        name: "Template",
        beforeEnter: adminAuth,
        component: () =>
          import(
            /* webpackChunkName: "EnterpriseUserList" */ "../views/Admin/Enterprise/Templates.vue"
          ),
      },
      {
        path: "/PDFView",
        name: "PDFView",
        beforeEnter: adminAuth,
        component: () => import(/* webpackChunkName: "Home" */ "../views/Admin/PDF/PDFView.vue"),
      },
];
// const routes = [...baseRoutes, ...enterpriseRoutes];
// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });
// router.beforeEach((to, from, next) => {
//   const domain = getDomainFromUrl();

//   if (domain !== null) {
//     router.matcher = new VueRouter({
//       mode: 'history',
//       base: process.env.BASE_URL,
//       routes: enterpriseRoutes,
//     }).matcher;
//   } else {
//     router.matcher = new VueRouter({
//       mode: 'history',
//       base: process.env.BASE_URL,
//       routes: baseRoutes,
//     }).matcher;
//   }

//   next();
// });

export default baseRoutes;