import Vue from "vue";
import VueRouter from "vue-router";
import enterpriseRoutes from "../router/enterprise";
import baseRoutes from "../router/index";

Vue.use(VueRouter);

export function getDomainFromUrl() {

  // for live deployemnet

  const url = new URL(window.location.href);
  const pathParts = url.pathname.split('/');
  const lastPath = pathParts[pathParts.length - 1];

  if (lastPath === '*') {
    return null;
  }

  const hostnameParts = url.hostname.split('.');
  const domain = hostnameParts[0];

  if (domain === 'app') {
    return null;
  } else {
    return domain ? domain : null;
  }
  };


// for local deployment

//   const path = window.location.pathname;
//   const parts = path.split("/"); // Split the path into parts

//   if (parts.length > 2) {
//     const domain = parts[1];
//     return domain;
//   } else {
//     return null; // Return null if the dynamic portion is not found
//   }
// };

const domain = getDomainFromUrl();

let routes;
console.log(domain);

if (domain !== null) {
  routes = enterpriseRoutes; // Use enterpriseRoutes if domain is not null
} else {
  routes = baseRoutes; // Use indexRoutes if domain is null
  
// Check if domainData exists in local storage
    const domainData = localStorage.getItem('domainData');
    if (domainData) {
      routes = enterpriseRoutes; // Use enterpriseRoutes if domainData is available
    } else {
      routes = baseRoutes; // Use baseRoutes if domain is null and domainData is not available
    }
}
// console.log(routes);
// Use the `routes` variable in your Vue router configuration
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
