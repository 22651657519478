<template>
  <div id="app">
    <!-- <header>
      <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <router-link class="navbar-brand" to="/">Vue Frontpage</router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li class="nav-item" v-show="!user">
              <router-link class="nav-link" to="/login">Login</router-link>
            </li>
            <li class="nav-item" v-show="!user">
              <router-link class="nav-link" to="/register"
                >Register</router-link
              >
            </li>
            <li class="nav-item" v-show="user">
              <a class="nav-link" href="#" @click="logout">Logout</a>
            </li>
          </ul>
        </div>
      </nav>
    </header> -->

    <main role="main">
      <router-view />
      <!-- <lightbox :items="images"></lightbox> -->
      <!-- <ChatBox/> -->
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getDomainFromUrl } from "./mixin"
// import ChatBox from './components/chatBot/ChatBox.vue';


export default {
  components: {
    // ChatBox,
  },

  computed: {
    ...mapGetters("auth", ["user"])
  },

  mounted() {
    try {
    const domain = getDomainFromUrl();
    if (domain) {
      this.handleUrlLoad();
    }

    if (localStorage.getItem("authToken")) {
      this.getUserData();
    }
  } catch (error) {
    console.error('Error in mounted hook:', error);
  }
  },

  methods: {
    ...mapActions("auth", ["sendLogoutRequest", "getUserData", "handleUrlLoad"]),

    logout() {
      this.sendLogoutRequest();
      this.$router.push("/");
    }
  }
};
</script>
