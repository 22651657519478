    import Vue from "vue";
    import VueRouter from "vue-router";

    Vue.use(VueRouter);

    const guest = (to, from, next) => {
    localStorage.removeItem("authToken");
    if (!localStorage.getItem("authToken")) {
        localStorage.removeItem("authToken");
        return next(next);
    } else {
        return next("/");
    }
    };
    
  const auth = (to, from, next) => {

  // Get the domainData from the local Storage
  const domainDataGet = localStorage.getItem("domainData"); 
  const domainData = JSON.parse(domainDataGet);

  //Extract domain from the current URL for deployment
  const url = new URL(window.location.href);
  const hostnameParts = url.hostname.split('.');
  const domain = hostnameParts[0]; // Retrieve the dynamic portion from the hostname

  if (domainData && domainData.domain_name && domain === domainData.domain_name) {
    
    var redirectToProfile = false;
    var redirectToCompany = false;

    if (localStorage.getItem("company_id")) {
      redirectToCompany = true;
    }

    if (localStorage.getItem("user_last_name")) {
      redirectToProfile = true;
    }

    if (localStorage.getItem("authToken") && localStorage.getItem("permissions") != "1") {
      if (localStorage.getItem("permissions") != 4) {
        if (redirectToCompany && redirectToProfile != false) {
          return next();
        } else {
          if (redirectToProfile == false) {
            return next("/profile");
          } else if (redirectToCompany == false) {
            return next("/company-profile");
          } else {
            return next();
          }
        }
      } else {
        return next(next);
      }
    } else {
      localStorage.removeItem("authToken");
      return next("/login");
    }
  } else {
    localStorage.removeItem("authToken");
     return next("/EntpageNotFound");
  }
 };
    // const authVerfier = (to, from, next) => {
    //   if (localStorage.getItem("authToken") && localStorage.getItem("permissions") != "1") {
    //     return next(next);
    //   } 
    //   else {
    //     localStorage.removeItem("authToken");
    //     return next("/login");
    //   }
    // };
    const simpleAuth = (to, from, next) => {
      if (localStorage.getItem("authToken") && localStorage.getItem("permissions") !== '1') {
          return next(next);
      }
      else {
        localStorage.removeItem("authToken");
        return next("/login");
      }
    };
    // const enterpriseAuth = (to, from, next) => {
    //   if (localStorage.getItem("authToken") && localStorage.getItem("permissions") == '5') {
    //       return next(next);
    //   }
    //   else {
    //     localStorage.removeItem("authToken");
    //     return next("/login");
    //   }
    // };

    const enterpriseAuth = (to, from, next) => {
      const permissions = localStorage.getItem("permissions");
      if (localStorage.getItem("authToken") && (permissions === '5' || permissions === '6' || '7' || '8')) {
          return next();
      } else {
          localStorage.removeItem("authToken");
          return next("/login");
      }
  };
  const enterpriseAdmin = (to, from, next) => {
    const permissions = localStorage.getItem("permissions");
    if (localStorage.getItem("authToken") && (permissions === '5' || '8')) {
        return next();
    } else {
        localStorage.removeItem("authToken");
        return next("/login");
    }
};
    // const adminAuth = (to, from, next) => {
    //   if (localStorage.getItem("authToken") && localStorage.getItem("permissions") == 1) {
    //       return next(next);
    //   }
    //   else {
    //     localStorage.removeItem("authToken");
    //     return next("/enterprise/login");
    //   }
    // };
    // const passwordRoute = (to, from, next) => {
    //   if (localStorage.getItem("verifyEmail")) {
    //     return next();
    //   } else {
    //     return next("/login");
    //   }
    // };
    // const emailVerify = (to, from, next) => {
    //   if (localStorage.getItem("emailToVerify")) {
    //     return next();
    //   } else {
    //     return next("/login");
    //   }
    // };

const enterpriseRoutes = [
    {
        path: "/",
        name: "EnterpriseHome",
        beforeEnter: auth,
        component: () => import(/* webpackChunkName: "Home" */ "../views/EnterpriseViews/Home.vue"),
    },
    {
        path: "/registry",
        name: "EnterpriseHomes",
        beforeEnter: simpleAuth,
        component: () => import(/* webpackChunkName: "Home" */ "../views/EnterpriseViews/Home.vue"),
      },
      // {
      //   path: "/:enterprise?/registries",
      //   name: "EnterpriseHomes",
      //   beforeEnter: adminAuth,
      //   component: () => import(/* webpackChunkName: "Home" */ "../views/EnterpriseViews/Home.vue"),
      // },
    {
        path: "/login",
        name: "EnterpriseLogin",
        beforeEnter: guest,
        component: () =>
        import(/* webpackChunkName: "Login" */ "../views/EnterpriseViews/Auth/Login.vue"),
    },
    {
      path: "/enterprisePackage",
      name: "EnterprisePackage",
      beforeEnter: enterpriseAdmin,
      component: () =>
        import(/* webpackChunkName: "Register" */ "../views/EnterpriseViews/EnterprisePackage.vue"),
    },
    {
        path: "/register",
        name: "EnterpriseRegister",
        beforeEnter: guest,
        component: () =>
        import(/* webpackChunkName: "Login" */ "../views/EnterpriseViews/Auth/Register.vue"),
    },
    {
        path: "/verification-email",
        name: "EnterpriseVerificationEmail",
        beforeEnter: guest,
        component: () =>
        import(/* webpackChunkName: "Verification_email"*/ "../views/EnterpriseViews/Auth/VerificationEmail.vue"),
    },
    {
        path: "/verified-email",
        name: "EnterpriseVerifiedEmail",
        beforeEnter: guest,
        component: () =>
        import(/* webpackChunkName: "verified-email" */ "../views/EnterpriseViews/Auth/VerifiedEmail.vue"),
    },
    {
        path: "/verify/:hash",
        name: "EnterpriseVerify",
        beforeEnter: auth,
        props: true,
        component: () =>
        import(/* webpackChunkName: "Verify" */ "../views/EnterpriseViews/Auth/Verify.vue"),
    },
    {
        path: "/forgot-password",
        name: "EnterpriseForgotPassword",
        beforeEnter: guest,
        component: () =>
        import(
            /* webpackChunkName: "ForgotPassword" */ "../views/EnterpriseViews/Auth/ForgotPassword/Forgot.vue"
        ),
    },
    {
        path: "/forgot-username",
        name: "EnterpriseForgotUsername",
        beforeEnter: guest,
        component: () =>
          import(
            /* webpackChunkName: "ForgotUsername" */ "../views/EnterpriseViews/Auth/ForgotPassword/ForgotUsername.vue"
          ),
      },
    {
        path: "/verify-email",
        name: "EnterpriseVerifyEmail",
        beforeEnter: guest,
        component: () =>
        import(
            /* webpackChunkName: "VerifyEmail" */ "../views/EnterpriseViews/Auth/ForgotPassword/VerifyEmail.vue"
        ),
    },
    {
        path: "/new-password",
        name: "EnterpriseNewPassword",
        beforeEnter: guest,
        component: () =>
        import(
            /* webpackChunkName: "NewPassword" */ "../views/EnterpriseViews/Auth/ForgotPassword/NewPassword.vue"
        ),
    },
    {
        path: "/password-update",
        name: "EnterprisePasswordUpdate",
        beforeEnter: guest,
        component: () =>
        import(
            /* webpackChunkName: "PasswordUpdate" */ "../views/EnterpriseViews/Auth/ForgotPassword/PasswordUpdate.vue"
        ),
    },
      {
        path: "/registry-signup",
        name: "EnterpriseRegistrySignupPage",
        beforeEnter: auth,
        component: () =>
          import(
            /* webpackChunkName: "RegistrySignupPage" */ "../views/EnterpriseViews/RegistrySignupPage.vue"
          ),
      },
      {
        path: "/globalnda-signup",
        name: "EnterpriseGlobalNDAForm",
        beforeEnter: auth,
        component: () =>
          import(
            /* webpackChunkName: "GlobalNDAForm" */ "../views/EnterpriseViews/GlobalNDAForm.vue"
          ),
      },
      {
        path: "/signed-agreements",
        name: "EnterpriseSignedAgreements",
        beforeEnter: auth,
        component: () =>
          import(
            /* webpackChunkName: "SignedAgreements" */ "../views/EnterpriseViews/SignedAgreements.vue"
          ),
      },
      {
        path: "/agreement-detail",
        name: "EnterpriseAgreementDetail",
        beforeEnter: auth,
        component: () =>
          import(
            /* webpackChunkName: "AgreementDetail" */ "../views/EnterpriseViews/AgreementDetail.vue"
          ),
      },
      {
        path: "/ndaform-table",
        name: "EnterpriseCompanyAgreements",
        beforeEnter: auth,
        component: () =>
          import(
            /* webpackChunkName: "CompanyAgreements" */ "../views/EnterpriseViews/CompanyAgreements.vue"
          ),
      },
      {
        path: "/profile",
        name: "EnterpriseProfile",
        beforeEnter: simpleAuth,
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/EnterpriseViews/Profile.vue"),
      },
      {
        path: "/company-profile",
        name: "EnterpriseCompanyProfilePage",
        beforeEnter: simpleAuth,
        component: () =>
          import(
            /* webpackChunkName: "CompanyProfilePage" */ "../views/EnterpriseViews/CompanyProfilePage.vue"
          ),
      },
      {
        path: "/thank",
        name: "EnterpriseThank",
        beforeEnter: auth,
        component: () =>
          import(/* webpackChunkName: "Thank" */ "../views/EnterpriseViews/Thank.vue"),
      },      
      {
        path: "/registries",
        name: "Homes",
        beforeEnter: enterpriseAuth,
        component: () => import(/* webpackChunkName: "Home" */ "../views/EnterpriseViews/Home.vue"),
      },
      {
        path: "/PDFView",
        name: "PDFView",
        beforeEnter: enterpriseAuth,
        component: () => import(/* webpackChunkName: "Home" */ "../views/Admin/PDF/PDFView.vue"),
      },
      {
        path: "/SignSuccess",
        name: "SignSuccess",
        beforeEnter: enterpriseAuth,
        component: () => import(/* webpackChunkName: "Home" */ "../views/Admin/PDF/SignSuccess.vue"),
      },
      // {
      //   path: "/users-signed",
      //   name: "UsersSigned",
      //   beforeEnter: enterpriseAuth,
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "SignedAgreements" */ "../views/Admin/User/usersSigned.vue"
      //     ),
      // },
      {
        path: "/companies",
        name: "Companies",
        beforeEnter: enterpriseAuth,
        component: () =>
          import(
            /* webpackChunkName: "SignedAgreements" */ "../views/Admin/Companies/companies.vue"
          ),
      },
      {
        path: "/company-view",
        name: "companyView",
        beforeEnter: enterpriseAuth,
        component: () =>
          import(
            /* webpackChunkName: "SignedAgreements" */ "../views/Admin/Companies/companyView.vue"
          ),
      },
      {
        path: "/company-detail-view",
        name: "companyDetailView",
        beforeEnter: enterpriseAuth,
        component: () =>
          import(
            /* webpackChunkName: "SignedAgreements" */ "../views/Admin/Companies/compUsers&Agreements.vue"
          ),
      },
      {
        path: "/user-view",
        name: "usersView",
        beforeEnter: enterpriseAuth,
        component: () =>
          import(
            /* webpackChunkName: "usersView" */ "../views/Admin/User/usersView.vue"
          ),
      },
      {
        path: "/nda-table",
        name: "CompanySigned",
        beforeEnter: enterpriseAuth,
        component: () =>
          import(
            /* webpackChunkName: "CompanyAgreements" */ "../views/CompanyAgreements.vue"
          ),
      },
      {
        path: "/enterprise-user",
        name: "EnterpriseUser",
        beforeEnter: enterpriseAuth,
        component: () =>
          import(
            /* webpackChunkName: "EnterpriseUser" */ "../views/Admin/User/EnterpriseUserView.vue"
          ),
      },
      {
        path: "/enterprise-userlsit",
        name: "EnterpriseUserList",
        beforeEnter: enterpriseAuth,
        component: () =>
          import(
            /* webpackChunkName: "EnterpriseUserList" */ "../views/Admin/Enterprise/EnterpriseUser.vue"
          ),
      },
      {
        path: "/enterprise-update",
        name: "EnterpriseUserUpdate",
        beforeEnter: enterpriseAuth,
        component: () =>
          import(
            /* webpackChunkName: "EnterpriseUserList" */ "../views/Admin/Enterprise/EnterpriseUserUpdate.vue"
          ),
      },
      {
        path: "/templates",
        name: "Template",
        beforeEnter: enterpriseAuth,
        component: () =>
          import(
            /* webpackChunkName: "EnterpriseUserList" */ "../views/Admin/Enterprise/Templates.vue"
          ),
      },




      // enterprise admin 
      {
        path: "/users-signed",
        name: "UsersSigned",
        beforeEnter: enterpriseAuth,
        component: () =>
          import(
            /* webpackChunkName: "SignedAgreements" */ "../views/EnterpriseAdmin/AdminUsers.vue"
          ),
      },
      {
        path: "/Enterprise-Companies",
        name: "EnterpriseCompanies",
        beforeEnter: enterpriseAuth,
        component: () =>
          import(
            /* webpackChunkName: "SignedAgreements" */ "../views/EnterpriseAdmin/AdminCompanies.vue"
          ),
      },
      {
        path: "/*",
        name: "EntpageNotFound",
        beforeEnter: guest,
        component: () =>
          import(
            /* webpackChunkName: "pageNotFound" */ "../views/EnterpriseViews/PageNotFound.vue"
          )
      },


      

    ];
    
    export default enterpriseRoutes;