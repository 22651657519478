import axios from "axios";
import { getDomainFromUrl } from "../mixin/index";

export default {
  namespaced: true,

  state: {
    userData: null,
    userDomain: null,
    domainData: null,
  },

  getters: {
    user: (state) => state.userData,
    userDomain: (state) => state.userDomain,
    domainData: (state) => state.domainData,
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user;
    },
    setUserDomain(state, domain) {
      state.userDomain = domain;
    },
    setDomainData(state, domainData) {
      state.domainData = domainData;
    },
  },

  actions: {
    getUserData({ commit }) {
      axios
        .get(process.env.VUE_APP_API_URL + "user/data")
        .then(response => {
          commit("setUserData", response.data.data);
          //setting domain
          const email = response.data.data.user.email;
          const address = email.split('@').pop()
          commit("setUserDomain", address);
          // check if user activate/DeActivate
          if(response.data.data.user.status == 2){
            this.$router.push({ name: "Login" });
          }
         
          //setting company ID
          if (response.data.data.company != null) {
            localStorage.setItem("company_id", response.data.data.company.id);
            localStorage.setItem("user_last_name", response.data.data.user.last_name);
            localStorage.setItem("permissions", response.data.data.user.role_id);
          } 
          
          else if (response.data.data.enterpriseCompany != null) {
            localStorage.setItem("company_id", response.data.data.enterpriseCompany.id);
            localStorage.setItem("user_last_name", response.data.data.user.last_name);
            localStorage.setItem("permissions", response.data.data.user.role_id);
          } 
          else {
            localStorage.removeItem("company_id");
            localStorage.removeItem("user_last_name");
            // localStorage.removeItem("permissions");
          }
        })
        .catch(() => {
          localStorage.removeItem("authToken");
        });
    },

    sendLoginRequest({ commit, dispatch }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "auth/signin", data)
        .then((response) => {
          if (response.data.statusCode === 200) {
            if(response.data.email_verification_status == 0){
            //SETTING DOMAIN ON LOGIN
            
            const email = response.data.user.email;
            const address = email.split('@').pop()
            commit("setUserDomain", address);
            commit("setUserData", response.data);
            dispatch("getUserData");
             // set enterprise id to local storage if enterpriseuser 
            if (response.data.user.enterprise_id !== null) {
              localStorage.setItem("enterprise_id", response.data.user.enterprise_id);
            }
            if (response.data.company != null) {
              localStorage.setItem("company_id", response.data.company.id);
            } else if (response.data.enterpriseCompany != null) { 
              localStorage.setItem("company_id", response.data.enterpriseCompany.id);
            }
            else {
              localStorage.removeItem("company_id");
            }
            localStorage.setItem("authToken", response.data.token);
            localStorage.setItem("permissions", response.data.user.role_id);

          }
          else{
            localStorage.setItem("emstatus", response.data.email_verification_status );
            return response;
          }
        }
        
          return response;
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
    sendRegisterRequest({ commit, dispatch }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "auth/signup", data)
        .then((response) => {
          if (response.data.statusCode === 200) {
            localStorage.setItem("authToken", response.data.token);
            dispatch("getUserData");
          }
          return response;
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
    sendEnterpriseRegisterRequest({ commit, dispatch }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "signup/enterprise_register", data)
        .then((response) => {
          if (response.data.statusCode === 200) {
            localStorage.setItem("authToken", response.data.token);
            dispatch("getUserData");
          }
          return response;
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
    sendResetPasswordRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "requestReset", data)
        .then((response) => {
          localStorage.setItem("verifyEmail", data.email);
          return response;
        });
    },
    codeVerify({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "codeVerfy", data)
        .then((response) => {
          localStorage.setItem("verifiedCode", data.code);
          return response;
        });
    },
    changePassword({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "passwordReset", data)
        .then((response) => {
          localStorage.removeItem("verifyEmail");
          localStorage.removeItem("verifiedCode");
          return response;
        });
    },
    verifyEmail({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .get(
          process.env.VUE_APP_API_URL +
            `verifyEmail?code=${data.code}&email=${data.email}`
        )
        .then((response) => {
          return response;
        });
    },
    sendLogoutRequest({ commit }) {
      return axios
        .post(process.env.VUE_APP_API_URL + "auth/signout")
        .then((response) => {
          if (response.data.statusCode === 200) {
            commit("setUserData", null);
            localStorage.removeItem("authToken");
            localStorage.removeItem("company_id");
            localStorage.removeItem("permissions");
            localStorage.removeItem("domain");
            localStorage.removeItem("enterprise_id");
            localStorage.removeItem("enterprise_id");
            // localStorage.removeItem("domainData")
            // localStorage.removeItem("user_last_name")
          }
          return response;
        });
    },
    sendVerifyResendRequest() {
      return axios.get(process.env.VUE_APP_API_URL + "email/resend");
    },
    sendVerifyRequest({ dispatch }, hash) {
      return axios
        .get(process.env.VUE_APP_API_URL + "email/verify/" + hash)
        .then(() => {
          dispatch("getUserData");
        });
    },
    handleUrlLoad({ commit }) {
      const domain = getDomainFromUrl();
      if (domain) {
        axios
          .post(`${process.env.VUE_APP_API_URL}enterpriseuser/checkdomain`, {
            domain,
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              const domainData = response.data.data;
              commit("setDomainData", domainData);
              localStorage.setItem(
                "domainData",
                JSON.stringify(response.data.data)
              );
            } else if (response.data.statusCode === 404) {
              if (domain !== "app") {
                window.location.href = "/*";
              }
              console.log(response.data.statusCode);
            } else {
              console.log(response.data.message);
            }
          });
      }
    },
  },
};
